(() => {

  const parent = document.querySelector('[id^="tabs"]');
  const tablist = parent.querySelector('ul[role="tablist"]');
  const tabs = tablist.querySelectorAll('a[role="tab"]');
  const tabpanels = parent.querySelectorAll('[role="tabpanel"]');

  const switchTab = (oldTab, newTab) => {

    let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
    let oldPanel = tabpanels[oldIndex];

    oldTab.removeAttribute('aria-selected');
    oldTab.setAttribute('tabindex', '-1');
    oldPanel.hidden = true;

    let newIndex = Array.prototype.indexOf.call(tabs, newTab);
    let newPanel = tabpanels[newIndex];

    newTab.focus();
    newTab.removeAttribute('tabindex');
    newTab.setAttribute('aria-selected', true);
    newPanel.hidden = false;
  }

  tabs.forEach((tab) => {
    tab.addEventListener('click', e => {
      e.preventDefault();

      let firstTab = tablist.querySelector('a[role="tab"]');
      let currentTab = tablist.querySelector('[aria-selected]');

      if (!currentTab) {
        switchTab(firstTab, e.currentTarget);
      }

      else if (e.currentTarget !== currentTab) {
        switchTab(currentTab, e.currentTarget);
      }
    });

    tab.addEventListener('keydown', e => {

      let key = e.which;
      let left = 37;
      let right = 39;
      let down = 40;

      let oldIndex = Array.prototype.indexOf.call(tabs, e.currentTarget);
      let oldTab = tabs[oldIndex];

      let newIndex = (key === left ? (oldIndex - 1) : (key === right ? (oldIndex + 1) : (key === down ? oldIndex : null)));
      let newTab = tabs[newIndex];
      let newPanel = tabpanels[newIndex];

      if (newIndex !== null) {
        e.preventDefault();
        (newIndex === oldIndex ? newPanel.focus() : (newTab ? switchTab(oldTab, newTab) : void 0));
      }
    });
  });
})()
